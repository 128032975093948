import React from "react";
import "./CardCasestudy.css";

const CardCasestudy = ({ footerLabel, onClick }) => {
  return (
    <div className="card-container" onClick={onClick}>
    <div className="card">Introduction</div>
    <div className="card">Research & Analysis</div>
    <div className="card">Proposed Solutions</div>
    <div className="card">Outcomes & Learnings</div>
     
    <p className="footer">{footerLabel}</p>

    </div>
  );
};

export default CardCasestudy;