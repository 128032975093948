import React from 'react'
import Mirror from './Mirror'
import Laptop from './Laptop'
import Notebook from './Notebook'
import './Table.css'

export default function Table({MirrorOnClick,LaptopOnClick, NotebookOnClick} ) {
  return (

    <div class="grid-container">
      <div class="grid-item-title">
        <p className="welcome-text">Hi, I'm Negin! Welcome to my digital table.</p>
      </div>
      <div class="grid-item-mirror grid-item-img"><Mirror onClick={MirrorOnClick}/></div>
      <div class="grid-item-laptop grid-item-img"><Laptop onClick={LaptopOnClick}/></div>
      <div class="grid-item-notebook grid-item-img"><Notebook onClick={NotebookOnClick}/> </div>
      <div class="grid-item-mirror-lable grid-item-lable">
        <p className=" mirror-grid-text-color ">This is my reflection. Click to learn more <b>About Me</b>.</p>
      </div>
      <div class="grid-item-laptop-lable grid-item-lable">
        <p className="laptop-grid-text-color">This is my laptop. Click here to explore My <b>Case Studies and Projects</b>.</p>
      </div>
      <div class="grid-item-notebook-lable grid-item-lable">
        <p className="notebook-grid-text-color">This is my notebook. Click here to <b>Contact Me</b>.</p>
      </div>
   
  </div>
  )
}


