import React, { useState, useEffect } from 'react';
import contactNotebookImage from '../../assets/notebook.png';
import './Socials.css';

export default function Socials() {
  const [circleCount, setCircleCount] = useState(10);

  useEffect(() => {
    const updateCircleCount = () => {
      const screenWidth = window.innerWidth;
      const containerWidth = screenWidth > 768 ? screenWidth * 0.6 : screenWidth +200; // Calculate container width
      const circleSize = 30; // Circle width in pixels
      const gap = 30; // Gap between circles in pixels
      const totalSize = circleSize + gap;
      const count = Math.floor(containerWidth / totalSize); // Fit circles dynamically
      setCircleCount(count);
    };

    window.addEventListener('resize', updateCircleCount); // Listen to window resize
    updateCircleCount(); // Initial calculation

    return () => window.removeEventListener('resize', updateCircleCount); // Cleanup
  }, []);

  const today = new Date();
  const customFormattedDate = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short', // e.g., "Jan"
    day: 'numeric',
  }).replace(' ', ', '); // Add comma after the month

  return (
    <div className="socials__container">
      <section className="socials__section">
        {/* Notebook Image Section */}
        <div className="socials__image-container">
          <img
            src={contactNotebookImage}
            alt="Contact Notebook"
            className="socials__notebook"
          />
        </div>

        {/* Content Section */}
        <div className="socials__content">
          {/* Dynamic Circle Holes */}
          <div className="socials__holes">
            {[...Array(circleCount)].map((_, index) => (
              <div key={index} className="socials__hole"></div>
            ))}
          </div>

          {/* Horizontal and Vertical Lines */}
          <div className="socials__line socials__line--one"></div>
          <div className="socials__line socials__line--two"></div>
          <div className="socials__line socials__line--three"></div>
          <div className="socials__line socials__line--four"></div>
          <div className="socials__line socials__line--six"></div>
          <div className="socials__line socials__line--seven"></div>
          <div className="socials__line socials__line--eight"></div>
          <div className="socials__line socials__line--nine"></div>
          <div className="socials__vertical-line"></div>

          {/* Title and Date */}
          <div className="socials__title">
            <p className="socials__title-text">My Contact</p>
          </div>
          <div className="socials__date">
            <p className="socials__title-text">{customFormattedDate}</p>
          </div>

          {/* Social Links */}
          <div className="socials__link socials__link--linkedin">
            <a
              href="https://www.linkedin.com/in/neginmashreghi/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="socials__link-text">LinkedIn</p>
            </a>
          </div>
          <div className="socials__link socials__link--github">
            <a
              href="https://github.com/NeginMashreghi"
              target="_blank"
              rel="noreferrer"
            >
              <p className="socials__link-text">GitHub</p>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
