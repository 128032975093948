import React from 'react'
import './Terminal.css'

export default function Terminal() {
  return (
    <div className='page'>
         <div className="page-header">
          <div className="red round-btn"> </div>
          <div className="yellow round-btn" > </div>
          <div className="green round-btn"></div>
          <p>Terminal</p>
        </div>
         <div className="page-body" style={{backgroundColor: "#141414"}}>
          <div className="terminal">
              <div className="second-line">
                  <p>Negins-Computer:~ neginmashreghi$</p>
                  <p className="typing-1"><b>cd My-GitHub-Projects</b></p>
              </div>
              <div className="third-line">
                  <p className="show-p-tag">Negins-Computer:MyProject neginmashreghi$</p>
                  <p className="typing-2"> <b>ls</b></p>
              </div>
              <div className="show-project-list">
              
                  <a href="https://github.com/NeginMashreghi/CaptoneProject-Arduino-AWS" target="_blank" rel="noreferrer">
                      <p>  &nbsp; HDL-Sensor-Project</p>
                  </a>
                  
                  <a href="https://bitbucket.org/neginmashreghi/sms-authentication/src/master/" target="_blank" rel="noreferrer">
                      <p>  &nbsp; SMS-Authentication-App</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/sms-authentication-test/src/master/" target="_blank" rel="noreferrer">
                      <p>  &nbsp; SMS-Authentication-Test</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/email-verification/src/master/" target="_blank" rel="noreferrer">
                      <p>  &nbsp; Email-Comformation-App</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/email-verification-test/src/master/" target="_blank" rel="noreferrer">
                      <p>  &nbsp; Email-Comformation-Test</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/addnumber/src/master/" target="_blank" rel="noreferrer">
                      <p>  &nbsp; Auto-Deploy-Test-Bitbucket-Pipeline</p>
                  </a>
                  <a href="https://bitbucket.org/neginmashreghi/datecalculator_frontend/src/master/" target="_blank" rel="noreferrer">
                      <p>  &nbsp; Date-Calculator</p>
                  </a>
              </div>  
            </div>  
         </div>
      </div>
  )
}
