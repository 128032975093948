import React, {useState} from 'react'
import laptopImage from '../../assets/laptop.png';
import './CaseStudyProject.css'
import Figma from './Figma/Figma';
import Terminal from './Terminal/Terminal'
import terminal from '../../assets/terminal.png';
import figma from '../../assets/figma.png';


export default function CaseStudyProject() {
    const [selectedContent, setSelectedContent] = useState("figma");
    const contentMap = {
        terminal: <Terminal/>,
        figma: <Figma/>,
      };
  
  return (
    <div className='laptop-main-div'>
        <section className='laptop-section' >
          <div className='laptop-image-continer'>
            <img
              src={laptopImage}
              alt="laptop Design" />
          </div>
          <div className='page-continer'>
            {selectedContent ? contentMap[selectedContent] : contentMap["figma"]}
            <p className='hint'>Click to See Another View</p>
            <div className='menu-btn-continer'>
              <div className="menu-btn-section img-space">
             
                <img
                  className='menu-img-btn  '
                  src={figma}
                  alt="menu-img-btn"
                  onClick={() => setSelectedContent("figma")} />
                {selectedContent === "figma" ? <div className="selection-indicator" /> : <div className="selection-indicator-empty" />}
  
              </div>
              <div className="menu-btn-section">
                <img
                  className='menu-img-btn'
                  src={terminal}
                   alt="menu-img-btn"
                  onClick={() => setSelectedContent("terminal")} />
                {selectedContent === "terminal" ? <div className="selection-indicator" /> : <div className="selection-indicator-empty" />}
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}
