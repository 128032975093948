import React from 'react';

import laptopImage from '../../assets/laptop.png';

const Laptop = ({onClick}) => {
  return (
    <div className="laptop-container">
      <img 
        src={laptopImage} 
        alt="Laptop Design" 
        className="laptop-image mainpage-img-btn shake-on-hover" 
       // onClick={() => window.scrollTo({ top: document.getElementById('projects').offsetTop, behavior: 'smooth' })}
        onClick={onClick}
      />
    </div>
  );
};

export default Laptop;