import React from 'react';

import mirrorImage from '../../assets/about-me-mirror.png';

const Mirror = ({onClick}) => {
  return (
    <div className="mirror-container">
      <img 
        src={mirrorImage} 
        alt="Mirror Design" 
        className="mirror-image mainpage-img-btn shake-on-hover" 
        onClick={onClick}
       // onClick={() => window.scrollTo({ top: document.getElementById('about-me').offsetTop, behavior: 'smooth' })}
      />
    </div>
  );
};

export default Mirror;