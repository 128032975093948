
// Importing dependencies and assets
import React from 'react';
import aboutMeMirrorImage from '../../assets/about-me-mirror.png';
import aboutMeStickerRegular from '../../assets/about-me-sticker-regular.png';
import aboutMeStickerPattern from '../../assets/about-me-sticker-pattern.png';
import './AboutMe.css';

// AboutMe Component: Displays a personal introduction section with images and text
export default function AboutMe() {
  return (
    <div className="about-me__container">
      <section className="about-me__section">
        {/* Left Section: Mirror Image */}
        <div className="about-me__image-container">
          <img
            src={aboutMeMirrorImage}
            alt="Mirror"
            className="about-me__mirror"
          />
        </div>
        {/* Right Section: Stickers and Content */}
        <div className="about-me__content">
          {/* Regular Sticker */}
          <img
            src={aboutMeStickerRegular}
            alt="Regular Sticker"
            className="about-me__sticker about-me__sticker--regular"
          />
          
          {/* Pattern Sticker */}
          <img
            src={aboutMeStickerPattern}
            alt="Pattern Sticker"
            className="about-me__sticker about-me__sticker--pattern"
          />
          
          {/* Text Content Section */}
          <div className="about-me__text-content">
            <p className="about-me__title">Hi, I’m Negin Mashreghi!</p>
            <p className="about-me__description">
              I’m a creative and technically skilled professional passionate about
              building user-centered, impactful products. With a strong foundation
              in product management and development, I thrive in startup environments
              where I help teams bring ideas to life through strategic planning,
              innovative problem-solving, and seamless collaboration.
            </p>
            <p className="about-me__description">
              I’m known for helping startups create MVPs, align product strategies,
              and prioritize core features that drive measurable success. Whether it’s
              leading workshops, crafting user stories, or guiding cross-functional
              teams, I’m all about turning great ideas into accessible, well-designed
              experiences.
            </p>
            <p className="about-me__description">
              With my background in full-stack development and agile methodologies,
              I bring a unique combination of creativity and technical expertise to
              every project. My mission? To empower startups, creators, and individuals
              by creating thoughtful, meaningful solutions that make a difference.
            </p>
          </div>

        </div>
      </section>
    </div>
  );
}

