import React from 'react'
import allPerojectIcon from '../../../assets/figma-allproject-icon.png'
import CircleIcon from './CircleIcon'
import CardCasestudy from './CardCasestudy'
import './Figma.css'


const HINGE_URL = 'https://www.figma.com/board/myCD2IPhca3mHwGoSdnoow/Hinge-Case-Study?node-id=0-1&p=f&t=Li8u02BVwWmd1et3-0'
const DAYONE_URL = 'https://www.figma.com/board/P9jIV7fjJH1sPhl6vZUGjd/Day-one-Research?t=rPtN4mRWWmkt0Kp5-0'
export default function Figma() {
  const handleRedirect = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className='page'>
        <div className="page-header">
          <div className="red round-btn"> </div>
          <div className="yellow round-btn" > </div>
          <div className="green round-btn"></div>
          <p>Figma</p>
        </div>
        <div className="page-body">

          {/* side menu section */}
          <div className="side-menu">
            
            <div className="inbox-holder"/>
            <div className="lable-section lable-section-small">
                <CircleIcon size={30} fontSize={15} />
                <p className="lable small-lable">Negin’s Case Study</p>
            </div>
       
            <div className="menu-item">
              <div className="menu-icon">
                <img  src={allPerojectIcon} alt="allPerojectIcon" />
              </div>
              <div className="menu-lable">All Projects</div>
            </div>

          </div>
         
         {/* continer section */}
          <div className="continer">
            
            <div className="lable-section lable-section-big ">
                <CircleIcon size={40} fontSize={20} />
                <p className="lable big-lable">Negin’s Case Study</p>
            </div>
            <div className="casestudy-list">
              <CardCasestudy footerLabel="Hinge" onClick={() => handleRedirect(HINGE_URL)}/>
              <CardCasestudy footerLabel="Day one" onClick={() => handleRedirect(DAYONE_URL)}/>
            </div>

          </div>

        </div>
      </div>
  )
}
