import React from "react";
import "./CircleIcon.css";


const CircleIcon = ({ size = 80, fontSize = 36, backgroundColor = "#ada04d", textColor = "#ffffff" }) => {
  const circleStyle = {
    width: size,
    height: size,
    backgroundColor: backgroundColor,
    color: textColor,
    fontSize: fontSize,
    lineHeight: `${size}px`, // Center the text vertically for better alignment
  };

  return (
    <div className="circle-icon" style={circleStyle}>
      <span className="circle-text">N</span>
    </div>
  );
};

export default CircleIcon;