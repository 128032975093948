import React from 'react';

import notebookImage from '../../assets/notebook.png';

const Notebook = ({onClick}) => {
  return (
    <div className="notebook-container">
      <img 
        src={notebookImage} 
        alt="Notebook Design" 
        className="notebook-image mainpage-img-btn shake-on-hover" 
        onClick= {onClick}
       // onClick={() => window.scrollTo({ top: document.getElementById('social-links').offsetTop, behavior: 'smooth' })}
      />
    </div>
  );
};

export default Notebook;