import React, { useState, useEffect, useRef }  from "react";

import Socials from  '../src/components/SocialsPage/Socials'
import CaseStudyProject from "../src/components/CaseStudyProject/CaseStudyProject"
import AboutMe from '../src/components/Aboutme/AboutMe'
import Table from '../src/components/Table/Table'
import topButtonIcons from '../src/assets/top-button-icons.png';

import './App.css';

function App() {

  const [showTopButton, setShowTopButton] = useState(false);

  // References for scrolling to specific sections
  const tableRef = useRef(null);
  const aboutMeRef = useRef(null);
  const caseStudyRef = useRef(null);
  const socialsRef = useRef(null);

  // Scroll button visibility logic
  useEffect(() => {
    const handleScroll = () => {
      const tableBottom = tableRef.current.offsetTop + tableRef.current.offsetHeight;

      // Show or hide "Scroll to Top" button
      if (window.scrollY > tableBottom - 50) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to section function
  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  // Scroll to top (Table component)
  const scrollToTop = () => {
    window.scrollTo({
      top: tableRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="app">

        <div ref={tableRef} className="table-continer" id="table">
        <Table
          MirrorOnClick = {() => scrollToSection(aboutMeRef)} 
          LaptopOnClick = {() => scrollToSection(caseStudyRef)} 
          NotebookOnClick ={() => scrollToSection(socialsRef)}
        />
        </div>
      
       <div ref={aboutMeRef}   id="about-me">
       <AboutMe  />
       </div>
       <div ref={caseStudyRef} id="projects">
       <CaseStudyProject/>
       </div>
       <div ref={socialsRef} id="socials">
       <Socials/>
       </div>
 
      
       {showTopButton && (
        <div className="topButtonIcons-div">
          <img
            src={topButtonIcons}
            alt="Scroll to Table"
            className="shake-on-hover"
            onClick={scrollToTop}
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              cursor: "pointer",
              zIndex: 1000,
              width: "50px",
              height: "50px",
            }}
          />
        </div>
      )}
 
    
    </div>
  );
}

export default App;



